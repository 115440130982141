<script setup>
// const { activeSite, navs, setCurrentEntry } = useSharedState();
const { setCurrentEntry } = useSharedState();

const { data } = await useAsyncGql({
    operation: 'FlexiblePage',
    // variables: { lang: activeSite.value.lang, slug: 'home' },
    variables: { slug: 'home' },
});

if (data?.value?.entry) {
    setCurrentEntry(data.value.entry);
}
</script>
<template>
    <div class="lg:pr-24">
        <main v-if="data?.entry?.flexible">
            <Flexible :sections="data.entry.flexible" />
        </main>
    </div>
</template>
